import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IAlarmHistories, IFcmAlarmState } from '../../../@types/@acs/fcmAlarm';
import axios from '../../../utils/axios';
import { firebaseAppInstance } from '../../../firebase'; 
import { getMessaging, getToken } from 'firebase/messaging';
import config from 'configs/config';
import { RootState } from 'redux/store';
import { IProject } from '../../../@types/@acs/project';

// ----------------------------------------------------------------------

const initialState: IFcmAlarmState = {
    isLoading: false,
    error: null,
    fcmToken: null,
    alarmHistories: []
};

const slice = createSlice({
    name: 'fcmAlarm',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

         // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        initialize(state) {
            state.isLoading = initialState.isLoading;
            state.error = initialState.error;
            state.fcmToken = initialState.fcmToken;
            // state.forms = initialState.forms;
            // state.form = initialState.form;
        },

        // SET FCM TOKEN
        setFcmToken(state, action) {
            state.fcmToken = action.payload;
        },

        // GET HISTORIES
        getAlarmHistoriesSuccess(state, action) {
            state.isLoading = false;
            state.alarmHistories = action.payload;
        },

        // isFcmTokenRegistrationNeeded SUCCESS, REGIST DEVICE SUCCESS, DELETE DEVICE SUCCESS
        loadingSuccess(state, action) {
            state.isLoading = action.payload;
        },
    }
});

export default slice.reducer;

// Actions (useSelector로 외부에서 사용)
export const {
    initialize,
    startLoading,
    setFcmToken,
    getAlarmHistoriesSuccess,
    loadingSuccess,
    // setFormManage,
    // setFormFile
} = slice.actions;

export function isFcmTokenRegistrationNeeded() {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            const permission = await Notification.requestPermission();
            if (permission === "granted") {
                // 초기화된 Firebase App Instance를 받아서 메시징 객체를 반환
                const messaging = getMessaging(firebaseAppInstance);
                const token = await getToken(messaging, {
                    // 프로젝트설정 > 클라우드메시징 > 웹 구성의 웹푸시인증서
                    vapidKey: config.fcm.vapidKey,
                });

                // 현재 사용자의 등록된 fcmToken 유무 상관없이 현재 디바이스에서 발급 받은 토큰으로 state 설정 (DB 디바이스 등록 아님)
                dispatch(slice.actions.setFcmToken(token));

                const response = await axios.get(`acs/fcm/token`);
                const fcmToken = response.data;
                if (fcmToken) {
                    if (token === fcmToken) {
                        // 현재 사용자의 등록된 fcmToken과 발급받은 token이 같으므로 토큰 갱신할 필요 없기에 창 안띄움
                        return false; //return 'isValid';
                    } else {
                        // 현재 사용자의 등록된 fcmToken과 발급받은 token이 다르므로, 현재 디바이스로 토큰 등록 여부 묻는 창 띄움
                        return true; //return 'different';
                    }
                } else {
                    // 등록된 토큰이 없으므로 토큰 등록 여부 묻는 창 띄움
                    return true; //return 'empty';
                }
            } else {
                return false; //return 'error';
            }
        } catch (error) {
            const errorStr = error!.toString();
            if (errorStr.includes("An SSL certificate error occurred when fetching the script")) {
                alert('An SSL certificate error occurred when fetching the script');
            }
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.loadingSuccess(false));
        }
    };
};

export function registDevice(fcmToken: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            const response = await axios.get(`/aps/w/auth/me`);
            const adskUserId = response.data.sub;

            const registData = {
                "UserId" : adskUserId,
                "FcmToken" : fcmToken
            };
            await axios.patch(`/acs/fcm`, registData);

            dispatch(slice.actions.loadingSuccess(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function deleteDevice() {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            const response = await axios.get(`/aps/w/auth/me`);
            const adskUserId = response.data.sub;
            
            await axios.delete(`/acs/fcm`, { data: { UserId: adskUserId } });

            dispatch(slice.actions.loadingSuccess(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

// 첫 pageNum은 1부터 시작
export function getAlarmHistory(projectIds: string[], pageSize: number, pageNum: number, isDescending: boolean = true, startDate: string = '', endDate: string = '') {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            dispatch(slice.actions.startLoading());

            // isDescending이 true면 내림차순(기본적으로 최신 히스토리부터 binding), false거나 사용하지 않는 default면 오름차순
            const today = new Date();
            const sixMonthsAgo = new Date(today.setMonth(today.getMonth() - 6));
            startDate = sixMonthsAgo.toISOString().split('T')[0]; // "YYYY-MM-DD" format
            endDate = new Date().toISOString().split('T')[0]; // Today's date in "YYYY-MM-DD" format

            const response = await axios.post(`/acs/fcm/history?&pageSize=${pageSize}&pageNumber=${pageNum}${isDescending ? '&isDescending=true' : '&isDescending=false'}${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`, projectIds);
            const histories = response.data.data;
            const totalResults = response.data.totalCount;

            const projects = await axios.get('/aps/w/projects');

            const response2 = await axios.get(`/aps/w/auth/me`);
            const adskUserId = response2.data.sub;

            // const list: IAlarmHistories[] = await Promise.all(histories.map(async (history: any) => {
            const list: IAlarmHistories[] = await Promise.all(histories.filter((history: any) => history.userId !== adskUserId).map(async (history: any) => {
                const project = projects.data.find((project: IProject) => project.id.replace(/^b\./, '') === history.projectId);

                return {
                    id: history.id,
                    userId: history.userId,
                    projectId: history.projectId,
                    // issueId: history.issueId,
                    category: history.category,
                    actionType: history.actionType,
                    actionDetail: history.actionDetail,
                    createdAt: history.createdAt,
                    // updatedAt: history.updatedAt,
                    totalResults: totalResults,
                    projectName: project.name,
                    description: history.description,
                } as IAlarmHistories;
            }));
            
            // dispatch
            dispatch(slice.actions.getAlarmHistoriesSuccess(list));

            
            // // 현재 state 가져오기
            // const currentState = getState().fcmAlarm;

            // // 새로운 배열 만들기 (기존 alarmHistories + list)
            // const updatedAlarmHistories = [...currentState.alarmHistories, ...list];

            // // dispatch
            // dispatch(slice.actions.getAlarmHistoriesSuccess(updatedAlarmHistories));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

// 메시지 전송 테스트 - 지정된 사용자에게 메시지 전송
export function sendMessageToUser(deviceToken: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            const message = {
                "title" : 'BIMlize Cloud User Message',
                "body" : '지정된 사용자 메시지 전송 테스트',
                "deviceToken" : deviceToken,
                "imageUrl" : 'https://picsum.photos/200/300'
            };
            await axios.post(`/acs/fcm`, message);

            dispatch(slice.actions.loadingSuccess(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

// 메시지 전송 테스트 - 프로젝트에 속한 사용자들에게 메시지 전송
export function sendMessageToProjectUser(projectId: string, title: string, body: string, actionType: string, actionDetail: string, target: string = '') {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            // const response = await axios.get(`/aps/o/construction/admin/v1/projects/${projectId}/users?force=true`);
            // const projectUsers = response.data.results;
            
            // 수행한 사람
            const response2 = await axios.get(`/aps/w/auth/me`);
            // const adskUserId = response2.data.sub;
            // const adskUserInfo = projectUsers.find((user: { autodeskId: any; }) => user.autodeskId === adskUserId);
            const user = response2.data;

            const id = projectId.replace(/^.+\./, ""); // b. 떼어내기
            const description= `${user.name}(${user.email})님이 ${body}`;
            const message = {
                "title" : title,            // 알람 제목
                "body" : description,       // 알람 내용
                "projectId" : id,
                "userId" : user.sub,
                "target" : target,          // 현재 사용 안함 : 공백 처리
                "category" : 'bimlize',     // BIMlize Cloud에서 사용하는 category는 'bimlize', webhook으로 서버에서 보내지는 알림의 category는 'webhook'
                "actionType" : actionType,
                "actionDetail" : actionDetail,
                "description" : description,
            };
            await axios.post(`/acs/fcm/projects`, message);

            dispatch(slice.actions.loadingSuccess(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

// 유저에게 이메일 전송
export function sendEmailToUser(to: string, targetName: string, actionType: string, actionDetail: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            const message = {
                "to": to,
                "subject": '[제목] 유저에게 이메일 전송 테스트',
                "userName": 'Test User',             
                "projectName": 'Test Project',
                "target": targetName,           // 이슈 이름 or 파일 이름
                "category": '',                 // 현재 이메일에서 사용 안함
                "actionType": actionType,       // file or issue
                "actionDetail": actionDetail    // add or modify or delete
            };
            await axios.post(`/acs/mail/send`, message);

            dispatch(slice.actions.loadingSuccess(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

// 전체 이메일 전송 - 프로젝트에 속한 사용자들에게 이메일 전송
export function sendEmailToProjectUser(project: IProject, targetName: string, actionType: string, actionDetail: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            // 수행한 사람
            const response = await axios.get(`/aps/w/auth/me`);
            const user = response.data;

            const id = project.id.replace(/^.+\./, ""); // b. 떼어내기
            const message = {
                "userId": user.sub,
                "userName": user.name,             
                "projectId": id,
                "projectName": project.name,
                "target": targetName,           // 이슈 이름 or 파일 이름
                "category": "",                 // 현재 이메일에서 사용 안함
                "actionType": actionType,       // 이메일 title에 사용
                "actionDetail": actionDetail    // 이메일 title에 사용
            };
            await axios.post(`/acs/mail/send/project`, message);

            dispatch(slice.actions.loadingSuccess(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};