import config from './configs/config';
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from 'firebase/messaging';

export const firebaseConfig = {
  apiKey: config.fcm.apiKey,
  authDomain: config.fcm.authDomain,
  projectId: config.fcm.projectId,
  storageBucket: config.fcm.storageBucket,
  messagingSenderId: config.fcm.messagingSenderId,
  appId: config.fcm.appId,
  measurementId: config.fcm.measurementId,
};

export const firebaseAppInstance = initializeApp(firebaseConfig);

// FOREGROUND MESSAGE
onMessage(getMessaging(firebaseAppInstance), (payload) => {
  const notificationTitle = payload.notification.title;
  const notificationOptions = {
    body: payload.notification.body,
    icon: payload.notification.image
  };
  if (Notification.permission === 'granted') {
    new Notification(notificationTitle, notificationOptions);
  }
});

// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('/firebase-messaging-sw.js')
//       .then((registration) => {
//         console.log('Service Worker registered with scope:', registration.scope);

//         registration.onupdatefound = () => {
//           const installingWorker = registration.installing;
//           installingWorker.onstatechange = () => {
//             if (installingWorker.state === 'installed') {
//               if (navigator.serviceWorker.controller) {
//                 console.log('New content is available; please refresh.');
//                 window.location.reload(); // 새로운 서비스 워커가 설치되면 페이지를 새로고침
//               } else {
//                 console.log('Content is cached for offline use.');
//               }
//             }
//           };
//         };
//       }).catch((error) => {
//         console.error('Service Worker registration failed:', error);
//       });
//   });
// }